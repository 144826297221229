<template>
  <div class="page-container">
    <el-col>
      <el-card>
        <div slot="header" class="clearfix">
          <span>课程报名</span>
        </div>

        <el-table :data="applicateList" border stripe>
          <el-table-column type="index" label="ID" width="50"/>
          <el-table-column prop="floorNo" label="报名人" />
          <el-table-column prop="remarks" label="报名课程" />
          <el-table-column prop="remarks" label="报名状态" />
          <el-table-column label="操作" fixed="right">
            <template v-slot="scoped">
              <el-button
                type="warning"
                size="mini"
                @click="createTeachRoom('edit', scoped.row.id)"
                >通过</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="deleteTeachRoom(scoped.row.id)"
                >驳回</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </el-col>
  </div>
</template>

<script>
export default {
  data () {
    return {
      applicateList: [{}]
    }
  }
}
</script>

<style></style>
